<template>
  <div></div>
  <!--<exception-page type="404" />-->
</template>

<script>
import { ExceptionPage } from '@/components'

export default {
  components: {
    ExceptionPage
  },
  created () {
    window.location.href = '/#/test/record'
  }
}
</script>

<style scoped>

</style>
